import React from "react";
import {
  Card as MuiCard,
  CardContent,
  CardMedia,
  Typography,
  CardActionArea,
} from "@mui/material";
import "./TeamCard.css";

const TeamCard = (props) => {
  return (
    <MuiCard className="container">
      <CardActionArea className="card">
        <CardMedia component="img" height="100%" image={props.img} alt="img" />
        <CardContent className="card-content">
          <Typography gutterBottom variant="h2" component="div">
            {props.name}
          </Typography>
          <Typography gutterBottom variant="h3" component="div">
            {props.job}
          </Typography>
          <Typography gutterBottom variant="caption" component="div">
            {props.description}
          </Typography>
        </CardContent>
      </CardActionArea>
    </MuiCard>
  );
};

export default TeamCard;
