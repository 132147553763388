import React from "react";
import { Typography, Grid, Box } from "@mui/material";
import "./NavBarActions.css";

const NavBarActions = () => {
  const scrollToServices = () => {
    document
      .getElementById("our-services")
      .scrollIntoView({ behavior: "smooth" });
  };

  const scrollToTeam = () => {
    document.getElementById("our-team").scrollIntoView({ behavior: "smooth" });
  };

  return (
    <Grid item xs={12} md={6}>
      <Box className="services-container">
        <Typography
          className="services"
          onClick={scrollToServices}
          gutterBottom
          variant="h2"
        >
          Our Services
        </Typography>
        <Typography
          className="services"
          onClick={scrollToTeam}
          gutterBottom
          variant="h2"
        >
          Meet the team
        </Typography>
      </Box>
    </Grid>
  );
};

export default NavBarActions;
