import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { createTheme, ThemeProvider } from "@mui/material";

const theme = createTheme({
  palette: {
    primary: {
      main: "#000000",
      contrastText: "#fff",
    },
    secondary: {
      main: "#6F4500",
      contrastText: "#000",
    },
  },

  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: "1rem",
          textTransform: "capitalize",
          backgroundColor: "#F39323",
          "&:hover": {
            backgroundColor: "#F39323",
          },
          width: "160px",
          marginTop: "20px",
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: "1rem",
        },
      },
    },
  },

  typography: {
    fontFamily: "Montserrat",

    caption1: {
      fontSize: "1.7em",
      "@media (max-width:600px)": {
        fontSize: "1rem",
      },
      fontWeight: 600,
      backgroundImage: "linear-gradient(to right, #F39323, #6F4500)",
      backgroundClip: "text",
      color: "transparent",
    },
    h1: {
      fontSize: "2em",
      "@media (max-width:600px)": {
        fontSize: "1.7rem",
      },
      fontWeight: 600,
    },
    h2: {
      fontSize: "1.3em",
      "@media (max-width:600px)": {
        fontSize: "1.1rem",
      },
      fontWeight: 600,
    },
    h3: {
      fontSize: "1em",
      fontWeight: 500,
    },
    h4: {
      fontSize: "1em",
      fontWeight: 300,
    },
    p: {
      fontSize: "1.10em",
      "@media (max-width:600px)": {
        fontSize: "1rem",
      },
      fontWeight: 300,
    },
    caption: {
      fontSize: "1em",
      fontWeight: 300,
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
  </React.StrictMode>
);
