import React from "react";
import Carousel from "../../components/Carousel/Carousel";
import golang from "../../assets/techLogos/golang.png";
import android from "../../assets/techLogos/android.png";
import aws from "../../assets/techLogos/aws.png";
import c from "../../assets/techLogos/c.png";
import digitalocean from "../../assets/techLogos/digitalocean.png";
import gcloud from "../../assets/techLogos/gcloud.png";
import javascript from "../../assets/techLogos/javascript.png";
import kotlin from "../../assets/techLogos/kotlin.png";
import mongodb from "../../assets/techLogos/mongodb.png";
import mysql from "../../assets/techLogos/mysql.png";
import nest from "../../assets/techLogos/nest.png";
import net from "../../assets/techLogos/net.png";
import next from "../../assets/techLogos/next.png";
import node from "../../assets/techLogos/node.png";
import react from "../../assets/techLogos/react.png";
import redis from "../../assets/techLogos/redis.png";
import vue from "../../assets/techLogos/vue.png";
import typescript from "../../assets/techLogos/typescript.png";

function Service2() {
  return (
    <>
      <Carousel>
        <img src={aws} width="110px" height="70px" alt="aws" />
        <img src={c} width="90px" height="90px" alt="c" />
        <img src={golang} width="190px" height="75px" alt="golang" />
        <img src={javascript} width="80px" height="80px" alt="javascript" />
        <img src={redis} width="200px" height="65px" alt="redis" />
        <img src={vue} width="180px" height="65px" alt="vue" />
        <img src={typescript} width="80px" height="80px" alt="typescript" />
        <img src={react} width="200px" height="70px" alt="react" />
        <img src={node} width="130px" height="80px" alt="node" />
      </Carousel>
      <Carousel reverse={true}>
        <img src={android} width="200px" height="55px" alt="android" />
        <img
          src={digitalocean}
          width="115px"
          height="115px"
          alt="digitalocean"
        />
        <img src={gcloud} width="300px" height="70px" alt="gcloud" />
        <img src={mongodb} width="230px" height="60px" alt="mongodb" />
        <img src={kotlin} width="180px" height="40px" alt="kotlin" />
        <img src={mysql} width="175px" height="175px" alt="mysql" />
        <img src={nest} width="200px" height="90px" alt="nest" />
        <img src={net} width="108px" height="90px" alt="net" />
        <img src={next} width="150px" height="80px" alt="next" />
      </Carousel>
    </>
  );
}

export default Service2;
