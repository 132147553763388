import React from "react";
import "./Carousel.css";

const Carousel = (props) => {
  const slideClassName = props.reverse ? "slide-reverse" : "slide";
  return (
    <div className="wrapper">
      <div className="slider">
        <div className={slideClassName}>{props.children}</div>
        <div className={slideClassName}>{props.children}</div>
      </div>
    </div>
  );
};

export default Carousel;
