import EmailIcon from "@mui/icons-material/Email";
import GitHubIcon from "@mui/icons-material/GitHub";
import { Box, Container, Grid, IconButton, Typography } from "@mui/material";
import React from "react";

const Footer = () => {
  return (
    <Container
      sx={{
        mt: "30px",
        mb: "60px",
      }}
    >
      <Grid
        sx={{
          display: "flex",
          "@media (max-width:850px)": {
            flexDirection: "column",
            gap: "0",
          },
          justifyContent: "space-around",
          gap: "150px",
        }}
      >
        {/* Left side - About Us */}
        <Grid item xs={12} md={6}>
          <Box sx={{ textAlign: "center" }}>
            <Typography variant="h2" sx={{ mb: "15px" }}>
              About Us
            </Typography>
            <Typography
              style={{ lineHeight: "30px" }}
              variant="h4"
              sx={{
                textAlign: "left",
                "@media (max-width:600px)": {
                  textAlign: "center",
                },
                mb: "20px",
              }}
            >
              Whether you are in need of software engineering expertise,
              consulting services, or our own proprietary products, we are here
              to help. Contact us today to learn more about how we can help your
              business succeed.
            </Typography>
          </Box>
        </Grid>

        {/* Right Side - Contact */}
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              mt: "30px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                "@media (max-width:850px)": {
                  flexDirection: "column",
                },
              }}
            >
              <IconButton
                sx={{ color: "#F39323" }}
                href="https://github.com/chocolat-chaud-io"
                target="_blank"
              >
                <GitHubIcon fontSize="large" />
              </IconButton>
              <Typography>GitHub</Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                "@media (max-width:850px)": {
                  flexDirection: "column",
                },
              }}
            >
              <IconButton
                sx={{ color: "#F39323" }}
                href="mailto:contact@chocolat.chaud.io"
                target="_blank"
              >
                <EmailIcon fontSize="large" />
              </IconButton>
              <a
                style={{ textDecoration: "none", color: "black" }}
                href="mailto:contact@chocolat.chaud.io"
              >
                contact@chocolat.chaud.io
              </a>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Footer;
