// import React, { useState } from "react";
import { Container, Box, Typography } from "@mui/material";
import Form from "../../components/Form/Form";

const ContactUs = () => {
  return (
    <Container sx={{ textAlign: "center", mt: "45px", mb: "80px" }}>
      <Box>
        <Typography id="contact-form" variant="h1" gutterBottom>
          Contact us
        </Typography>
      </Box>
      <Box>
        <Typography variant="h3" sx={{ mb: "20px" }}>
          We'd love to hear from you!
        </Typography>
      </Box>
      <Form />
    </Container>
  );
};

export default ContactUs;
