import React from "react";
import { Grid, Box, Typography } from "@mui/material";
import logo from "../../assets/logo.png";

const NavBarLogo = () => {
  return (
    <Grid item xs={12} md={6}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "10px",
          "@media (max-width:600px)": {
            flexDirection: "column",
          },
        }}
      >
        <img src={logo} alt="logo" height="50px" width="50px" />
        <Typography variant="h2">Chocolat Chaud</Typography>
      </Box>
    </Grid>
  );
};

export default NavBarLogo;
