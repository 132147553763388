import React from "react";
import { Container, Grid } from "@mui/material";
import NavBarLogo from "./NavBarLogo";
import NavBarActions from "./NavBarActions";

const NavBar = () => {
  return (
    <Container
      maxWidth={false}
      sx={{
        height: "7vh",
        boxShadow: "0 8px 6px -11px black",
        "@media (max-width:800px)": {
          height: "18vh",
        },
      }}
    >
      <Grid
        sx={{
          display: "flex",
          alignItems: "center",
          "@media (max-width:800px)": {
            flexDirection: "column",
            gap: "15px",
            mt: "20px",
          },
          justifyContent: "space-around",
          gap: "40px",
          mt: "10px",
        }}
      >
        <NavBarLogo />
        <NavBarActions />
      </Grid>
    </Container>
  );
};

export default NavBar;
