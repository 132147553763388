import React from "react";
import { Grid, Box, Typography } from "@mui/material";
import "./Features.css";

const FeaturesDescription = (props) => {
  return (
    <>
      <Grid className="features-description-container" item xs={12} md={6}>
        <Box className="reveal fade-right">
          <Typography gutterBottom variant="h2">
            {props.title}
          </Typography>
          <Typography
            variant="p"
            display="block"
            style={{ lineHeight: "30px" }}
          >
            {props.description}
          </Typography>
        </Box>
      </Grid>
    </>
  );
};

export default FeaturesDescription;
