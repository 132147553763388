import React from "react";
import { Grid, Box } from "@mui/material";
import "./Features.css";

const FeaturesImg = (props) => {
  return (
    <>
      <Grid item xs={12} md={6}>
        <Box
          className="reveal fade-left"
          component="img"
          alt="logo"
          src={props.img}
        />
      </Grid>
    </>
  );
};

export default FeaturesImg;
