import React from "react";
import { Button as MuiButton } from "@mui/material";

const Button = (props) => {
  return (
    <MuiButton variant="contained" onClick={props.onClick} type={props.type}>
      {props.text}
    </MuiButton>
  );
};

export default Button;
