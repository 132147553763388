import React from "react";
import { Container, Grid, useMediaQuery } from "@mui/material";
import FeaturesDescription from "./FeaturesDescription";
import FeaturesImg from "./FeaturesImg";

function reveal() {
  var reveals = document.querySelectorAll(".reveal");

  for (var i = 0; i < reveals.length; i++) {
    var windowHeight = window.innerHeight;
    var elementTop = reveals[i].getBoundingClientRect().top;
    var elementVisible = 150;

    if (elementTop < windowHeight - elementVisible) {
      reveals[i].classList.add("active");
    } else {
      reveals[i].classList.remove("active");
    }
  }
}

window.addEventListener("scroll", reveal);

const Features = (props) => {
  const matches = useMediaQuery("(min-width:850px)");
  return (
    <Container>
      <Grid
        className="features-container"
        sx={{
          "@media (max-width:850px)": {
            flexDirection: "column",
            gap: 0
          },
        }}
      >
        {props.reverse && matches ? (
          <>
            <FeaturesDescription
              title={props.title}
              description={props.description}
            />
            <FeaturesImg img={props.img} />
          </>
        ) : (
          <>
            <FeaturesImg img={props.img} />
            <FeaturesDescription
              title={props.title}
              description={props.description}
            />
          </>
        )}
      </Grid>
    </Container>
  );
};

export default Features;
