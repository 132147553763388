import { Container, Box } from "@mui/system";
import React from "react";
import TeamCard from "../../components/TeamCard/TeamCard";
import Eddie from "../../assets/teamPicture/eddie.jpg";
import Gabby from "../../assets/teamPicture/gabby.jpg";
import Phil from "../../assets/teamPicture/phil.jpg";
import { Typography } from "@mui/material";

const Team = () => {
  return (
    <Container
      xs={12}
      md={12}
      maxWidth={false}
      sx={{
        marginTop: "40px",
        background: "linear-gradient(to bottom right, #F39323, #FFD681, #6F4500)",
        height: "63vh",
        "@media (max-width:600px)": {
          height: "148vh",
        },
      }}
    >
      <Typography
        id="our-team"
        variant="h1"
        sx={{ textAlign: "center", pt: "40px" }}
      >
        Our Team
      </Typography>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          gap: "90px",
          "@media (max-width:600px)": {
            flexDirection: "column",
            alignItems: "center",
            gap: "20px",
          },
          pt: "50px",
        }}
      >
        <TeamCard
          img={Eddie}
          name="Eddie Zhou"
          job="DevOps & Back-End Expert"
          description="Software engineer specialized in developing complex backend and in building application deployment pipelines."
        />
        <TeamCard
          img={Gabby}
          name="Gabriel Bottari"
          job="CEO & Founder"
          description="Challenge driven software engineer with experience in project management and on every level of the development cycle."
        />
        <TeamCard
          img={Phil}
          name="Philippe Le"
          job="Frontend Specialist"
          description="Software engineer dedicated in enriching and faciliting users with his frontend and mobile experience."
        />
      </Box>
    </Container>
  );
};

export default Team;
