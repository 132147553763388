import emailjs from "@emailjs/browser";
import { Box, styled, TextField } from "@mui/material";
import React, { useRef } from "react";
import Button from "../Button/Button";

const CssTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "#F39323",
  },
  "& .MuiOutlinedInput-root": {
    "&:hover fieldset": {
      borderColor: "#F39323",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#F39323",
    },
  },
});

const Form = () => {
  const form = useRef();

  const sendEmail = (event) => {
    event.preventDefault();

    emailjs
      .sendForm(
        "service_3bxznvm",
        "template_u9f2gaa",
        form.current,
        "yX724DgjFRaWAPxyJ"
      )
      .then((result) => {
        console.log("Sent");
      });
    event.target.reset();
  };

  return (
    <Box
      component="form"
      sx={{
        display: "flex",
        flexDirection: "column",
        flexWrap: "wrap",
        alignItems: "center",
        "& .MuiTextField-root": {
          m: 1,
          width: "400px",
        },
      }}
      ref={form}
      onSubmit={sendEmail}
    >
      <CssTextField
        id="custom-css-outlined-input"
        required
        label="Name"
        type="text"
        name="name"
      />
      <CssTextField
        id="custom-css-outlined-input"
        autoComplete="on"
        required
        label="Email"
        type="email"
        name="email"
      />
      <CssTextField
        id="custom-css-outlined-input"
        multiline
        rows={5}
        required
        label="Message"
        name="message"
      />
      <Button text="Send" type="submit" />
    </Box>
  );
};

export default Form;
