import { Typography } from "@mui/material";
import React from "react";
import service1 from "../../assets/service1.png";
import service2 from "../../assets/service2.png";
import Features from "../../components/Features/Features";

const Service1 = () => {
  return (
    <>
      <Typography
        id="our-services"
        variant="h1"
        gutterBottom
        sx={{
          textAlign: "center",
          pt: "80px",
        }}
      >
        What we do
      </Typography>
      <Features
        img={service1}
        title="We excel at complex product and full stack development."
        description="Our team delivers top-notch results on time and on budget. We've worked with a wide range of clients across multiple industries, and we're proud to say that we've consistently delivered exceptional results that meet and exceed expectations."
      ></Features>
      <Features
        reverse={true}
        img={service2}
        title="Our team is robust, scalable and a highly Agile."
        description="At our company, we understand that agility is key to success in today's fast-paced business world. That's why we've built a team that is both highly adaptable and incredibly resilient. Whether we're working on a complex project or responding to a rapidly changing market, we have the skills and expertise needed to stay ahead of the curve."
      ></Features>
    </>
  );
};

export default Service1;
