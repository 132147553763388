import Navbar from "./sections/Navbar/NavBar";
import Landing from "./sections/Landing/Landing";
import Services from "./sections/Services/Services";
import Team from "./sections/Team/Team";
import Technologies from "./sections/Services/Technologies";
import ContactUs from "./sections/ContactUs/ContactUs";
import Footer from "./sections/Footer/Footer";
import "./index.css";

const App = () => {
  return (
    <div>
      <Navbar />
      <Landing />
      <Technologies />
      <Services />
      <Team />
      <ContactUs />
      <Footer />
    </div>
  );
};

export default App;
