import { Box, Container, Grid, Typography } from "@mui/material";
import TypeWriterEffect from "react-typewriter-effect";
import React from "react";
import landing from "../../assets/techLogos/landing.png";
import Button from "../../components/Button/Button";

const Landing = () => {
  const scrollToForm = () => {
    document
      .getElementById("contact-form")
      .scrollIntoView({ behavior: "smooth" });
  };

  return (
    <Container maxWidth={false}>
      <Grid
        sx={{
          display: "flex",
          alignItems: "center",
          "@media (max-width:600px)": {
            flexDirection: "column",
            gap: "0px",
            margin: "40px auto",
          },
          justifyContent: "space-around",
          gap: "50px",
          margin: "20px 200px 0 200px",
        }}
      >
        {/* Company Intro */}
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              "@media (max-width:600px)": {
                alignItems: "center",
                textAlign: "center",
                pb: "30px",
              },
            }}
          >
            <Typography variant="caption1">
              <TypeWriterEffect
                startDelay={100}
                cursorColor="black"
                text=" &lt; Chocolat Chaud /&gt;"
                typeSpeed={100}
                hideCursorAfterText="true"
              />
            </Typography>

            <Typography
              gutterBottom
              variant="h2"
              style={{ lineHeight: "30px", marginTop: "20px" }}
            >
              We’re a team of software engineers based in Canada. We develop our
              own products & provide software engineering and consulting to our
              clients.
            </Typography>
            <Button onClick={scrollToForm} text="Contact Us" />
          </Box>
        </Grid>

        {/* Company Logo */}
        <Grid item xs={12} md={6}>
          <Box
            component="img"
            sx={{
              h: 500,
              w: 500,
              maxHeight: { xs: 400, md: 500 },
              maxWidth: { xs: 400, md: 500 },
              pb: "60px",
              "@media (max-width:600px)": {
                flexDirection: "column",
                pb: "0px",
                pt: "10px",
              },
              pt: "40px",
            }}
            alt="logo"
            src={landing}
          />
        </Grid>
      </Grid>
    </Container>
  );
};

export default Landing;
